const generateColorsWithName = () => {
  let result = {}

  Object.keys(colors).forEach(colorIndex => {
    let colorIndexNew = `${colorIndex.charAt(0).toUpperCase()}${colorIndex.slice(1)}`

    let colorName = colorIndexNew.split(/(?=[A-Z])/).join(' ')

    result[colorIndex] = { index: colorIndex, name: colorName, color: colors[colorIndex], contrast: colorPallet[colorIndex].text }
  });
  return result
}

export const colors = {
  darkByzantium: '#4b244a',
  blueVioletCrayola: '#7366BD',
  paleCerulean: '#98c1d9',
  deserSand: '#e4be9e',
  outrageusOrange: '#ff784f',
  englishViolet: '#3a3042',
  peach: '#ffe19c ',
  mandarin: '#EF8354',
  seaGreen: '#388659',
  eggplant: '#684551',
  maximumBluePurple: '#beb7df',
  burntSienna: '#de6b48',
  mantis: '#7bc950',
  starCommandBlue: '#197bbd',
  lapisLazuli: '#125e8a',
  spaceCadet: '#2c2c54',
  eggshell: '#f9f5e3',
  ultraRed: '#ef798a',
  barnRed: '#6f1a07',
  richBlack: '#023c40',
  safetyYellow: '#f1d302',
  bdazzledBlue: '#235789',
  darkLiverHorses: '#5c4742',
  apricot: '#ffcab1',
  cadetBlue: '#69a2b0',
  russianGreen: '#659157',
  olivine: '#a1c084',
  paradisePink: '#e05263',
  internationalOrangeEngineering: '#b80c09',
  midnightEagleGreen: '#204b57',
  feldgrau: '#4a5859',
  onyx: '#32373b',
  brickRed: '#c83e4d',
  sunray: '#f4b860',
  unbleachedSilk: '#f4d6cc',
  cadetGrey: '#C7CEDB',
  rhythm: '#646881',
  maximumBlueGreen: '#62BEC1',
  maroonX11: '#B8336A',
  lightBlue: '#',
  wisteria: '#C490D1',
  antiqueRuby: '#832232',
  keyLime: '#EAF27C',
  royalPurple: '#7B4B94',
  middleBluePurple: '#7D82B8',
  viridian: '#57886C',
  asparagus: '#8BB174',
  littleBoyBlue: '#89AAE6',
  midnightGreenEagleGreen: '#1A535C',
  naplesYellow: '#FFE66D',
  amazon: '#417B5A',
  pastelPink: '#EFA7A7',
  orangeYellowCrayola: '#FFD972',
  ming: '#326771',
  bottleGreen: '#0B6E4F',
  ochre: '#D17A22',
  redwood: '#A15E49',
  cambridgeBlue: '#BFD3C1',
  polishedPine: '#68A691',
  roseDust: '#AA6373',
  skobeloff: '#046865',
  copperRed: '#C46D5E',
  darkSlateGray: '#294D4A',
  turquoiseGreen: '#A8D4AD',
  brunswickGreen: '#29524A',
  middlePurple: '#D387AB',
  forrestGreenCrayola: '#4F9D69',
  fuzzyWuzzy: '#C1666B',
  charmPink: '#DB93B0',
  paolaVeroneseGreen: '#23967F',
  hookersGreen: '#357266',
  englishRed: '#B23A48',
  cornflowerBlue: '#7692FF',
  amaranth: '#9F2B68',
  darkPink: '#AA336A',
  orchid: '#DA70D6',
  cerise: '#DA4167',
  sugarPlum: '#8E5572',
  jazzberryJam: '#9E0059',
  rebeccaPurple: '#654597',
  lavenderFloral: '#AB81CD',
  mauve: '#E2ADF2',
  fandago: '#B0228C',
  flickrPink: '#EA3788',
  cameoPink: '#F2BAC9',
  vividTangarine: '#FF8E72',
  bitterSweet: '#ED6A5E',
  pumpkin: '#F3752B',
  cherryBlossomPink: '#FFB7C3',
  blush: '#E75A7C',
  crimsonUA: '#941c2f',
  lightGreenCustom: '#338BA8'
};

export const colorPallet = {
  default: {
    text: 'white',
    color: 'black',
  },
  darkByzantium: {
    text: 'white',
    color: colors.darkByzantium,
  },
  paleCerulean: {
    text: 'white',
    color: colors.paleCerulean,
  },
  feldgrau: {
    text: 'white',
    color: colors.feldgrau,
  },
  brickRed: {
    text: 'white',
    color: colors.brickRed,
  },
  rhythm: {
    text: 'white',
    color: colors.rhythm,
  },
  onyx: {
    text: 'white',
    color: colors.onyx,
  },
  deserSand: {
    text: 'black',
    color: colors.deserSand,
  },
  cadetGrey: {
    text: 'black',
    color: colors.cadetGrey,
  },
  sunray: {
    text: 'black',
    color: colors.sunray,
  },
  unbleachedSilk: {
    text: 'black',
    color: colors.unbleachedSilk,
  },
  outrageusOrange: {
    text: 'white',
    color: colors.outrageusOrange,
  },
  englishViolet: {
    text: 'white',
    color: colors.englishViolet,
  },
  peach: {
    text: 'black',
    color: colors.peach,
  },
  mandarin: {
    text: 'white',
    color: colors.mandarin,
  },
  seaGreen: {
    text: 'white',
    color: colors.seaGreen,
  },
  eggplant: {
    text: 'white',
    color: colors.eggplant,
  },
  burntSienna: {
    text: 'white',
    color: colors.burntSienna,
  },
  mantis: {
    text: 'white',
    color: colors.mantis,
  },
  starCommandBlue: {
    text: 'white',
    color: colors.starCommandBlue,
  },
  lapisLazuli: {
    text: 'white',
    color: colors.lapisLazuli,
  },
  spaceCadet: {
    text: 'white',
    color: colors.spaceCadet,
  },
  eggshell: {
    text: 'black',
    color: colors.eggshell,
  },
  ultraRed: {
    text: 'white',
    color: colors.ultraRed,
  },
  barnRed: {
    text: 'white',
    color: colors.barnRed,
  },
  richBlack: {
    text: 'white',
    color: colors.richBlack,
  },
  safetyYellow: {
    text: 'black',
    color: colors.safetyYellow,
  },
  bdazzledBlue: {
    text: 'white',
    color: colors.bdazzledBlue,
  },
  darkLiverHorses: {
    text: 'white',
    color: colors.darkLiverHorses,
  },
  apricot: {
    text: 'black',
    color: colors.apricot,
  },
  cadetBlue: {
    text: 'white',
    color: colors.cadetBlue,
  },
  russianGreen: {
    text: 'white',
    color: colors.russianGreen,
  },
  olivine: {
    text: 'white',
    color: colors.olivine,
  },
  paradisePink: {
    text: 'white',
    color: colors.paradisePink,
  },
  internationalOrangeEngineering: {
    text: 'white',
    color: colors.internationalOrangeEngineering,
  },
  midnightEagleGreen: {
    text: 'white',
    color: colors.midnightEagleGreen,
  },
  blueVioletCrayola: {
    text: 'white',
    color: colors.blueVioletCrayola,
  },
  maximumBluePurple: {
    text: 'white',
    color: colors.maximumBluePurple,
  },
  maximumBlueGreen: {
    text: 'black',
    color: colors.maximumBlueGreen,
  },
  maroonX11: {
    text: 'white',
    color: colors.maroonX11,
  },
  lightBlue: {
    text: 'black',
    color: colors.lightBlue,
  },
  wisteria: {
    text: 'black',
    color: colors.wisteria,
  },
  antiqueRuby: {
    text: 'white',
    color: colors.antiqueRuby,
  },
  keyLime: {
    text: 'black',
    color: colors.keyLime,
  },
  royalPurple: {
    text: 'white',
    color: colors.royalPurple,
  },
  middleBluePurple: {
    text: 'white',
    color: colors.middleBluePurple,
  },
  viridian: {
    text: 'white',
    color: colors.viridian,
  },
  asparagus: {
    text: 'white',
    color: colors.asparagus,
  },
  littleBoyBlue: {
    text: 'black',
    color: colors.littleBoyBlue,
  },
  midnightGreenEagleGreen: {
    text: 'white',
    color: colors.midnightGreenEagleGreen,
  },
  naplesYellow: {
    text: 'black',
    color: colors.naplesYellow,
  },
  amazon: {
    text: 'white',
    color: colors.amazon,
  },
  pastelPink: {
    text: 'black',
    color: colors.pastelPink,
  },
  orangeYellowCrayola: {
    text: 'black',
    color: colors.orangeYellowCrayola,
  },
  ming: {
    text: 'white',
    color: colors.ming,
  },
  bottleGreen: {
    text: 'white',
    color: colors.bottleGreen,
  },
  ochre: {
    text: 'white',
    color: colors.ochre,
  },
  redwood: {
    text: 'white',
    color: colors.redwood,
  },
  cambridgeBlue: {
    text: 'black',
    color: colors.cambridgeBlue,
  },
  polishedPine: {
    text: 'white',
    color: colors.polishedPine,
  },
  roseDust: {
    text: 'white',
    color: colors.roseDust,
  },
  skobeloff: {
    text: 'white',
    color: colors.skobeloff,
  },
  copperRed: {
    text: 'white',
    color: colors.copperRed,
  },
  darkSlateGray: {
    text: 'white',
    color: colors.darkSlateGray,
  },
  turquoiseGreen: {
    text: 'black',
    color: colors.turquoiseGreen,
  },
  brunswickGreen: {
    text: 'white',
    color: colors.brunswickGreen,
  },
  middlePurple: {
    text: 'black',
    color: colors.middlePurple,
  },
  forrestGreenCrayola: {
    text: 'white',
    color: colors.forrestGreenCrayola,
  },
  fuzzyWuzzy: {
    text: 'white',
    color: colors.fuzzyWuzzy,
  },
  charmPink: {
    text: 'black',
    color: colors.charmPink,
  },
  paolaVeroneseGreen: {
    text: 'white',
    color: colors.paolaVeroneseGreen,
  },
  hookersGreen: {
    text: 'white',
    color: colors.hookersGreen,
  },
  englishRed: {
    text: 'white',
    color: colors.englishRed,
  },
  cornflowerBlue: {
    text: 'white',
    color: colors.cornflowerBlue,
  },
  amaranth: {
    text: 'white',
    color: colors.amaranth,
  },
  darkPink: {
    text: 'white',
    color: colors.darkPink,
  },
  orchid: {
    text: 'black',
    color: colors.orchid,
  },
  cerise: {
    text: 'white',
    color: colors.cerise,
  },
  sugarPlum: {
    text: 'white',
    color: colors.sugarPlum,
  },
  jazzberryJam: {
    text: 'white',
    color: colors.jazzberryJam,
  },
  rebeccaPurple: {
    text: 'white',
    color: colors.rebeccaPurple,
  },
  lavenderFloral: {
    text: 'white',
    color: colors.lavenderFloral,
  },
  mauve: {
    text: 'black',
    color: colors.mauve,
  },
  fandago: {
    text: 'white',
    color: colors.fandago,
  },
  flickrPink: {
    text: 'white',
    color: colors.flickrPink,
  },
  cameoPink: {
    text: 'black',
    color: colors.cameoPink,
  },
  vividTangarine: {
    text: 'black',
    color: colors.vividTangarine,
  },
  bitterSweet: {
    text: 'white',
    color: colors.bitterSweet,
  },
  pumpkin: {
    text: 'white',
    color: colors.pumpkin,
  },
  cherryBlossomPink: {
    text: 'black',
    color: colors.cherryBlossomPink,
  },
  blush: {
    text: 'white',
    color: colors.blush,
  },
  crimsonUA: {
    text: 'white',
    color: colors.crimsonUA,
  },
  lightGreenCustom: {
    text: 'white',
    color: colors.lightGreenCustom
  }
};

export const colorsWithName = generateColorsWithName()

export const colorsArray = Object.keys(colors)

export const colorsObject = () => {
  const tempArray = [...colorsArray]
  const result = {}
  tempArray.forEach(element => {
    result[element] = element
  });
  return result
}
