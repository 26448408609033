import { moduleNames } from "data/enums/modules";
import { enumPermissionModules as EPM, enumPermissions as EP } from "../enums/authorization";

const en = {
  MenuSegment: {
    admin: 'Admin',
    agent: 'Agent',
    user: 'User',
    system: 'System',
    dev: 'Dev'
  },
  MenuLink: {
    [moduleNames.ADMIN_HOME]: 'Home',
    [moduleNames.ADMIN_USERS]: 'Users',
    [moduleNames.ADMIN_TESTIMONIALS]: 'Testimonials',
    [moduleNames.ADMIN_AMENITIES_DROPDOWN]: 'Amenities',
    [moduleNames.ADMIN_AMENITIES]: 'Single',
    [moduleNames.ADMIN_AMENITIES_CATEGORIES]: 'Categories',
    [moduleNames.ADMIN_COUNTRIES]: 'Countries',
    [moduleNames.ADMIN_CITIES]: 'Cities',
    [moduleNames.ADMIN_VILLAS]: 'Villas',
    [moduleNames.ADMIN_YACHTS]: 'Yachts',
    [moduleNames.ADMIN_CONTACT]: 'Contact',
    [moduleNames.ADMIN_RESERVATIONS]: 'Reservations',
    [moduleNames.ADMIN_ICAL]: 'iCal',
    [moduleNames.ADMIN_ANALYTICS_DROPDOWN]: 'Analytics',
    [moduleNames.ADMIN_ANALYTICS_ITEMS]: 'Items',
    [moduleNames.ADMIN_ANALYTICS_PROMOTIONS]: 'Promotions',
    [moduleNames.ADMIN_PROFILE]: 'Profile',
    [moduleNames.AGENT_ITEMS]: 'Items',

    [`${moduleNames.DEV_BUILDER_DROPDOWN}_admin`]: 'Builder',
    [`${moduleNames.DEV_BUILDER_MODULE}_admin`]: 'Module',
  },
  PermissionsModalBody: {
    agent: 'Agent',
    [EPM.AGENT_ITEMS]: 'Agent items',
    [EP.AGENT_ITEMS_LIST]: 'List',
    [EP.AGENT_ITEMS_GET]: 'Get',
    [EP.AGENT_ITEMS_PROMOTION]: 'Promotion',

    [EPM.ADMIN_PROFILE]: 'Profile',
    [EP.PROFILE_GET]: 'Get',
    [EP.PROFILE_UPDATE]: 'Update',

    admin: 'Admin',
    [EPM.ADMIN_USERS]: 'Users',
    [EP.ADMIN_USERS_LIST]: 'List',
    [EP.ADMIN_USERS_GET]: 'Get',
    [EP.ADMIN_USERS_CREATE]: 'Create',
    [EP.ADMIN_USERS_UPDATE]: 'Update',
    [EP.ADMIN_USERS_DELETE]: 'Delete',

    [EPM.ADMIN_ANALYTICS]: 'Analytics',
    [EP.ADMIN_ANALYTICS_ITEMS]: 'Items',
    [EP.ADMIN_ANALYTICS_PROMOTIONS]: 'Promotions',

    [EPM.ADMIN_PERMISSIONS]: 'Permission',
    [EP.ADMIN_PERMISSIONS_GET]: 'Get',
    [EP.ADMIN_PERMISSIONS_UPDATE]: 'Update',

    [EPM.ADMIN_ITEMS]: 'Items',
    [EP.ADMIN_ITEMS_LIST]: 'List',
    [EP.ADMIN_ITEMS_CREATE]: 'Create',
    [EP.ADMIN_ITEMS_UPDATE]: 'Update',
    [EP.ADMIN_ITEMS_DELETE]: 'Delete',
    [EP.ADMIN_ITEMS_GET]: 'Get',
    [EP.ADMIN_ITEMS_IMAGE_DELETE]: 'Image delete',
    [EP.ADMIN_ITEMS_PROMOTION]: 'Promotion link',
    [EP.ADMIN_ITEMS_AGENT_DISABLE]: 'Agent disable',

    [EPM.ADMIN_CONTACT]: 'Contact',
    [EP.ADMIN_CONTACT_LIST]: 'List',

    [EPM.ADMIN_RESERVATIONS]: 'Reservations',
    [EP.ADMIN_RESERVATIONS_LIST]: 'List',
    [EP.ADMIN_RESERVATIONS_UPDATE]: 'Update',

    [EPM.ADMIN_TESTIMONIALS]: 'Testimonials',
    [EP.ADMIN_TESTIMONIALS_LIST]: 'List',
    [EP.ADMIN_TESTIMONIALS_CREATE]: 'Create',
    [EP.ADMIN_TESTIMONIALS_UPDATE]: 'Update',
    [EP.ADMIN_TESTIMONIALS_DELETE]: 'Delete',

    [EPM.ADMIN_AMENITIES]: 'Amenities',
    [EP.ADMIN_AMENITIES_LIST]: 'List',
    [EP.ADMIN_AMENITIES_CREATE]: 'Create',
    [EP.ADMIN_AMENITIES_UPDATE]: 'Update',
    [EP.ADMIN_AMENITIES_DELETE]: 'Delete',


    [EPM.ADMIN_AMENITIES_CATEGORIES]: 'Amenities categories',
    [EP.ADMIN_AMENITIES_CATEGORIES_LIST]: 'List',
    [EP.ADMIN_AMENITIES_CATEGORIES_CREATE]: 'Create',
    [EP.ADMIN_AMENITIES_CATEGORIES_UPDATE]: 'Update',
    [EP.ADMIN_AMENITIES_CATEGORIES_DELETE]: 'Delete',

    [EPM.ADMIN_ICAL]: 'iCal',
    [EP.ADMIN_ICAL_LIST]: 'List',
    [EP.ADMIN_ICAL_CREATE]: 'Create',
    [EP.ADMIN_ICAL_UPDATE]: 'Update',
    [EP.ADMIN_ICAL_DELETE]: 'Delete',

    [EPM.ADMIN_COUNTRIES]: 'Countries',
    [EP.ADMIN_COUNTRIES_LIST]: 'List',
    [EP.ADMIN_COUNTRIES_UPDATE]: 'Update',
    [EP.ADMIN_COUNTRIES_CREATE]: 'Create',

    [EPM.ADMIN_CITIES]: 'Cities',
    [EP.ADMIN_CITIES_LIST]: 'List',
    [EP.ADMIN_CITIES_CREATE]: 'Create',
    [EP.ADMIN_CITIES_UPDATE]: 'Update',
    [EP.ADMIN_CITIES_DELETE]: 'Delete',

    common: 'Common',
    [EPM.PROFILE]: 'Profile',
    [EP.PROFILE_GET]: 'Get',
    [EP.PROFILE_UPDATE]: 'Update',

  },
  DashboardFooter: {
    companyName: 'UniqueStays', /* SHOULD_CHANGE */
    craftedLeft: 'Crafted with',
    craftedRight: 'by Damir&Nadir' /* SHOULD_CHANGE */
  },
  Logout: {
    title: 'Logout',
    loading: 'Waiting to safely log out',
    completed: 'You have been safely logged out',
    button: 'Back to Login'
  },
  RequestErrors: {
    default: 'Unknown error',
    browser: {
      ERR_NETWORK: 'Network error'
    },
    network: {
      401: 'Unauthenticated user',
      403: 'Insufficient permissions',
      405: 'Method not allowed',
      406: 'Request not acceptable'
    },
    internal: {
      1: "Method not allowed",
      2: "Login data not passed",
      3: "Invalid username format",
      4: "Invalid password format",
      5: "Invalid login credentials",
      6: "Database error",
      7: "POST request not in correct format",
      8: "Field not in correct format",
      9: "Entry already exists in the database",
      10: "ID needs to be passed through GET",
      11: "Requested ID not found in the database",
      12: "One of the search parameters must be passed. [id]",
      13: "GET parameter not in correct format",
      14: "Requested user not found in the database",
      15: "Requested country not found in the database",
      16: "User does not have permission for the requested module/action",
      17: "MySQL table name must be passed",
      18: "Value not found in the selected table",
      19: "Error in passing key/value parameters",
      20: "File format not allowed",
      21: "Category not found in the table",
      22: "Product not found in the table",
      23: "Product ID not passed",
      24: "Variation not found in the table",
      25: "Selected table not allowed for viewing via this method",
      26: "User not found. General error",
      27: "Requested number not found or is used in one of the campaigns",
      28: "No users for payout in the requested period",
      29: "Only pending payments can be deleted",
      30: "Status parameter must be passed",
      31: "Cannot switch from existing to requested status",
      32: "Contact is already taken or owned by a user",
      33: "Entry already received in the system",
      34: "Status must be passed through GET",
      35: "Ticket does not exist or is closed for further response",
      36: "User does not have an active selected payout method",
      37: "Entered amount is not sufficient for the selected method",
      38: "Selected method cannot be used at the moment",
      39: "Selected method is currently not active on your account",
      40: "Insufficient funds in your account at the moment",
      41: "Username must be passed through query parameter",
      42: "User not found in the database",
      43: "Password reset link already requested for the user. Check spam/junk.",
      44: "Token not found",
      45: "Password entries must match",
      46: "Communication error with external service",
      47: "Campaign has exhausted total budget, increase budget to reactivate campaign",
      48: "Reason for campaign rejection must be entered. Field: reject_comment",
      49: "Reason for creative rejection must be entered. Field: reject_comment",
      50: "Input length exceeds allowed limit",
      51: "Error uploading file",
      52: "Cannot edit completed payout",
      53: "Entries did not pass validation",
      54: "One of the parameters must be passed [subid, country, device]",
      55: "Demo admin user cannot be deleted",
      56: "Endpoint missing on external server",
      57: "Error on external server",
      58: "Error inserting response into database",
      59: "External server responded with 401",
      60: "Error in fb/google analytics parameters",
      61: "Offer image does not exist on the server, upload required",
      62: "Dates do not match",
      63: "Requested date does not have a defined price for that property",
      64: "Update status not sent",
      65: "Selected amenity is already assigned to a property, deleting it will result in deletion from all properties it is assigned to",
      66: "Database error",
      67: "Minimum number of days not met",
      68: "The selected city is set on an item, and cannot be deleted",
    },
    custom: {}
  },

  [moduleNames.ADMIN_TESTIMONIALS]: {
    browserTabTitle: 'Uniquestays - Admin | Testimonials',/* SHOULD_CHANGE */
    createLabel: 'Add testimonial',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create testimonial',
    editModalTitle: 'Edit testimonial',
    deleteModalTitle: 'Delete testimonial',
  },

  [moduleNames.ADMIN_ICAL]: {
    browserTabTitle: 'Uniquestays - Admin | iCal',/* SHOULD_CHANGE */
    createLabel: 'Add iCal',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create iCal',
    editModalTitle: 'Edit iCal',
    deleteModalTitle: 'Delete iCal',
  },

  [moduleNames.ADMIN_RESERVATIONS]: {
    browserTabTitle: 'Uniquestays - Admin | Reservations',/* SHOULD_CHANGE */
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editModalTitle: 'Edit reservation',
  },

  [moduleNames.ADMIN_COUNTRIES]: {
    browserTabTitle: 'Uniquestays - Admin | Countries',/* SHOULD_CHANGE */
    createLabel: 'Add country',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create country',
    editModalTitle: 'Edit country',
    deleteModalTitle: 'Delete country',
  },

  [moduleNames.ADMIN_VILLAS]: {
    browserTabTitle: 'Uniquestays - Admin | Villas',/* SHOULD_CHANGE */
    createLabel: 'Add villa',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create villa',
    editModalTitle: 'Edit villa',
    deleteModalTitle: 'Delete villa',
    promotionLabel: 'Promotion link'
  },

  [moduleNames.ADMIN_YACHTS]: {
    browserTabTitle: 'Uniquestays - Admin | Yachts',/* SHOULD_CHANGE */
    createLabel: 'Add yacht',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create yacht',
    editModalTitle: 'Edit yacht',
    deleteModalTitle: 'Delete yacht',
  },

  [moduleNames.ADMIN_CITIES]: {
    browserTabTitle: 'Uniquestays - Admin | CITIES',/* SHOULD_CHANGE */
    createLabel: 'Add city',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create city',
    editModalTitle: 'Edit city',
    deleteModalTitle: 'Delete city',
  },

  [moduleNames.ADMIN_AMENITIES]: {
    browserTabTitle: 'Uniquestays - Admin | Amenities',/* SHOULD_CHANGE */
    createLabel: 'Add amenity',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create amenity',
    editModalTitle: 'Edit amenity',
    deleteModalTitle: 'Delete amenity',
  },

  [moduleNames.ADMIN_AMENITIES_CATEGORIES]: {
    browserTabTitle: 'Uniquestays - Admin | Amenity categories',/* SHOULD_CHANGE */
    createLabel: 'Add category',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create category',
    editModalTitle: 'Edit category',
    deleteModalTitle: 'Delete category',
  },

  [moduleNames.ADMIN_CONTACT]: {
    browserTabTitle: 'Uniquestays - Admin | Contact',/* SHOULD_CHANGE */
  },

  [moduleNames.ADMIN_USERS]: {
    browserTabTitle: 'Uniquestays - Admin | Users',/* SHOULD_CHANGE */
    createLabel: 'Add user',
    editLabel: 'Edit',
    authLabel: 'Auth',
    permissionsLabel: 'Permissions',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create user',
    editModalTitle: 'Edit user',
    authModalTitle: 'Edit user password',
    permissionModalTitle: 'User permissions',
    deleteModalTitle: 'Delete user',
    deleteModalBulkTitle: 'Delete users',
    editModalBulkTitle: 'Edit users',
  },

  [moduleNames.ADMIN_PROFILE]: {
    browserTabTitle: 'Uniquestays - Profile', /* SHOULD_CHANGE */
  },

  [moduleNames.AGENT_ITEMS]: {
    browserTabTitle: 'Uniquestays - Agent items', /* SHOULD_CHANGE */
  },

  [moduleNames.ADMIN_ANALYTICS_PROMOTIONS]: {
    browserTabTitle: 'Uniquestays - Admin | Analytics promotions',/* SHOULD_CHANGE */
  },

  [moduleNames.ADMIN_ANALYTICS_ITEMS]: {
    browserTabTitle: 'Uniquestays - Admin | Analytics items',/* SHOULD_CHANGE */
  },
}

export default en;