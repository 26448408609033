import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { convertPermissionsToFlatArray } from 'utilities/dataFormatting/permissions';

import commonApi from 'api/commonApi'

export const getSession = createAsyncThunk(
  'auth/session',
  async (userId, thunkAPI) => {
    const data = await commonApi.requestSessionInfo(userId)
    return data
  }
)

const states = {
  v1: {
    authenticated: false,
    sessionExpired: false,
    permissions: [],
    role: null,
    token: null,
    user: null,
    login: null,
    loading: {
      login: false,
      logout: false,
      sessionInfo: false,
    },
    lastEditedTimestamp: 0,
    previousUser: null
  }
};


let initialState = states.v1

export const authMigration = {
  1: () => states.v1
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      const { payload } = action
      const preparedPermissions = convertPermissionsToFlatArray(payload?.session?.permission)
      state.loading.login = false;
      state.authenticated = true;
      state.token = payload?.token;
      state.role = payload?.session?.user?.data?.status;
      state.user = payload?.session?.user;
      state.permissions = preparedPermissions;
      state.login = payload?.session?.login;
      state.sessionExpired = false
      state.lastEditedTimestamp = payload?.session?.user?.edited.timestamp || 0;
      state.previousUser = null
    },
    fastLogin: (state, action) => {
      const { payload } = action
      const preparedPermissions = convertPermissionsToFlatArray(payload?.session?.permission)
      state.previousUser = { ...state }
      state.loading.login = false;
      state.authenticated = true;
      state.token = payload?.token;
      state.role = payload?.session?.user?.data?.status;
      state.user = payload?.session?.user;
      state.permissions = preparedPermissions;
      state.login = payload?.session?.login;
      state.sessionExpired = false;
      state.lastEditedTimestamp = payload?.session?.user?.edited.timestamp || 0;
    },
    logout: (state) => {
      state.loading.login = false;
      state.authenticated = false;
      state.token = null;
      state.role = null;
      state.user = null;
      state.permissions = [];
      state.login = null;
      state.sessionExpired = false
      state.lastEditedTimestamp = 0;
      state.previousUser = null
    },
    sessionExpired: (state) => {
      state.sessionExpired = true
      state.token = null
    },
    lastEditedUpdate: (state, action) => {
      const { payload } = action
      state.lastEditedTimestamp = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSession.fulfilled, (state, action) => {
      const { payload } = action

      const preparedPermissions = convertPermissionsToFlatArray(payload?.permission)
      state.authenticated = true;
      state.role = payload?.user?.data?.status;
      state.user = payload?.user;
      state.permissions = preparedPermissions;
      state.login = payload?.login;
      state.sessionExpired = false
      state.loading.sessionInfo = false

    })
    builder.addCase(getSession.rejected, (state, action) => {
      state.loading.sessionInfo = false
    })
    builder.addCase(getSession.pending, (state, action) => {
      state.loading.sessionInfo = true
    })
  },
});

export const { login, logout, sessionExpired, lastEditedUpdate, fastLogin } = authSlice.actions;

export const selectUser = (state) => state.auth.user;
export const selectAuthenticated = (state) => state.auth.authenticated;
export const selectPermissions = (state) => state.auth.permissions;
export const selectRole = (state) => state.auth.role;
export const selectAuth = (state) => state.auth;
export const selectActive = (state) => state.auth.user?.data?.info?.is_active
export const selectApproved = (state) => state.auth.user?.data?.info?.is_approved
export const selectLastEdited = (state) => state.auth.lastEditedTimestamp;
export const selectUserID = (state) => state.auth.user?.id;
export const selectToken = (state) => state.auth.token;
export const selectSessionExpired = (state) => state.auth.sessionExpired;
export const selectUsername = (state) => state.auth?.user?.data?.username;
export const selectPreviousUser = (state) => state.auth?.previousUser;

export default authSlice.reducer;