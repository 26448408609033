import { createTheme } from '@mui/material'

const semiDarkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      customMd: 1030,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'Poppins'
  },
  palette: {
    primary: {
      contrastText: "#fff",
      main: '#0087CA'
    },
    secondary: {
      contrastText: "#fff",
      main: "#195C7D",
    },
    buttonReject: {
      contrastText: "#fff",
      main: 'B8350C'
    },
    background: {
      main: '#ffffff',
      mainDarker2: '#f9f9f9',
      mainDarker5: '#f2f2f2',
      dark: '#f2f4f4'
    },
    button: {
      main: '#FFFFFF',
    },
    backgroundDark: {
      main: '#1a233a',
      dark: '#161e31',
      light: '#2d3651',
      contrastText: '#fff'
    },
    menu: {
      background: {
        main: '#1a233a',
        dark: '#0a0a1e'
      }
    }
  },
  shape: { borderRadius: 5 },
});

export default semiDarkTheme