import { apiPaths, apiActions } from "./db"

export const enumRoles = { ADMIN: 1, AGENT: 2, BANNED: 99 } /* SHOULD_CHANGE */

const enumPermissionActions = { ...apiActions }
const EPA = enumPermissionActions

export const enumPermissionModules = { ...apiPaths }
const EPM = enumPermissionModules

export const enumPermissions = {
  // Admin - Profile
  PROFILE_GET: `${EPM.PROFILE}/${EPA.GET}`,
  PROFILE_UPDATE: `${EPM.PROFILE}/${EPA.UPDATE}`,
  // Admin - Permissions
  ADMIN_PERMISSIONS_GET: `${EPM.ADMIN_PERMISSIONS}/${EPA.GET}`,
  ADMIN_PERMISSIONS_UPDATE: `${EPM.ADMIN_PERMISSIONS}/${EPA.UPDATE}`,
  // Admin - Users
  ADMIN_USERS_LIST: `${EPM.ADMIN_USERS}/${EPA.LIST}`,
  ADMIN_USERS_GET: `${EPM.ADMIN_USERS}/${EPA.GET}`,
  ADMIN_USERS_CREATE: `${EPM.ADMIN_USERS}/${EPA.CREATE}`,
  ADMIN_USERS_UPDATE: `${EPM.ADMIN_USERS}/${EPA.UPDATE}`,
  ADMIN_USERS_DELETE: `${EPM.ADMIN_USERS}/${EPA.DELETE}`,

  // Admin - Testimonials
  ADMIN_TESTIMONIALS_LIST: `${EPM.ADMIN_TESTIMONIALS}/${EPA.LIST}`,
  ADMIN_TESTIMONIALS_CREATE: `${EPM.ADMIN_TESTIMONIALS}/${EPA.CREATE}`,
  ADMIN_TESTIMONIALS_UPDATE: `${EPM.ADMIN_TESTIMONIALS}/${EPA.UPDATE}`,
  ADMIN_TESTIMONIALS_DELETE: `${EPM.ADMIN_TESTIMONIALS}/${EPA.DELETE}`,

  //Admin - Contact
  ADMIN_CONTACT_LIST: `${EPM.ADMIN_CONTACT}/${EPA.LIST}`,

  //Admin - Reservations
  ADMIN_RESERVATIONS_LIST: `${EPM.ADMIN_RESERVATIONS}/${EPA.LIST}`,
  ADMIN_RESERVATIONS_UPDATE: `${EPM.ADMIN_RESERVATIONS}/${EPA.UPDATE}`,

  // Admin - Ical
  ADMIN_ICAL_LIST: `${EPM.ADMIN_ICAL}/${EPA.LIST}`,
  ADMIN_ICAL_CREATE: `${EPM.ADMIN_ICAL}/${EPA.CREATE}`,
  ADMIN_ICAL_UPDATE: `${EPM.ADMIN_ICAL}/${EPA.UPDATE}`,
  ADMIN_ICAL_DELETE: `${EPM.ADMIN_ICAL}/${EPA.DELETE}`,

  // Admin - Items
  ADMIN_ITEMS_LIST: `${EPM.ADMIN_ITEMS}/${EPA.LIST}`,
  ADMIN_ITEMS_CREATE: `${EPM.ADMIN_ITEMS}/${EPA.CREATE}`,
  ADMIN_ITEMS_UPDATE: `${EPM.ADMIN_ITEMS}/${EPA.UPDATE}`,
  ADMIN_ITEMS_DELETE: `${EPM.ADMIN_ITEMS}/${EPA.DELETE}`,
  ADMIN_ITEMS_GET: `${EPM.ADMIN_ITEMS}/${EPA.GET}`,
  ADMIN_ITEMS_PROMOTION: `${EPM.ADMIN_ITEMS}/${EPA.PROMOTION}`,
  ADMIN_ITEMS_IMAGE_DELETE: `${EPM.ADMIN_ITEMS}/${EPA.IMAGE_DELETE}`,
  ADMIN_ITEMS_AGENT_DISABLE: `${EPM.ADMIN_ITEMS}/${EPA.AGENT_DISABLE}`,

  // Admin - Amenities
  ADMIN_AMENITIES_LIST: `${EPM.ADMIN_AMENITIES}/${EPA.LIST}`,
  ADMIN_AMENITIES_GET: `${EPM.ADMIN_AMENITIES}/${EPA.GET}`,
  ADMIN_AMENITIES_CREATE: `${EPM.ADMIN_AMENITIES}/${EPA.CREATE}`,
  ADMIN_AMENITIES_UPDATE: `${EPM.ADMIN_AMENITIES}/${EPA.UPDATE}`,
  ADMIN_AMENITIES_DELETE: `${EPM.ADMIN_AMENITIES}/${EPA.DELETE}`,

  // Admin - Amenities categories
  ADMIN_AMENITIES_CATEGORIES_LIST: `${EPM.ADMIN_AMENITIES_CATEGORIES}/${EPA.LIST}`,
  ADMIN_AMENITIES_CATEGORIES_CREATE: `${EPM.ADMIN_AMENITIES_CATEGORIES}/${EPA.CREATE}`,
  ADMIN_AMENITIES_CATEGORIES_UPDATE: `${EPM.ADMIN_AMENITIES_CATEGORIES}/${EPA.UPDATE}`,
  ADMIN_AMENITIES_CATEGORIES_DELETE: `${EPM.ADMIN_AMENITIES_CATEGORIES}/${EPA.DELETE}`,

  // Admin - Countries
  ADMIN_COUNTRIES_LIST: `${EPM.ADMIN_COUNTRIES}/${EPA.LIST}`,
  ADMIN_COUNTRIES_UPDATE: `${EPM.ADMIN_COUNTRIES}/${EPA.UPDATE}`,
  ADMIN_COUNTRIES_CREATE: `${EPM.ADMIN_COUNTRIES}/${EPA.CREATE}`,

  // Admin - Cities
  ADMIN_CITIES_LIST: `${EPM.ADMIN_CITIES}/${EPA.LIST}`,
  ADMIN_CITIES_UPDATE: `${EPM.ADMIN_CITIES}/${EPA.UPDATE}`,
  ADMIN_CITIES_CREATE: `${EPM.ADMIN_CITIES}/${EPA.CREATE}`,
  ADMIN_CITIES_DELETE: `${EPM.ADMIN_CITIES}/${EPA.DELETE}`,

  //Agent - Items
  AGENT_ITEMS_LIST: `${EPM.AGENT_ITEMS}/${EPA.LIST}`,
  AGENT_ITEMS_GET: `${EPM.AGENT_ITEMS}/${EPA.GET}`,
  AGENT_ITEMS_PROMOTION: `${EPM.AGENT_ITEMS}/${EPA.PROMOTION}`,

  //Admin - Analytics
  ADMIN_ANALYTICS_ITEMS: `${EPM.ADMIN_ANALYTICS}/${EPA.ITEMS}`,
  ADMIN_ANALYTICS_PROMOTIONS: `${EPM.ADMIN_ANALYTICS}/${EPA.PROMOTIONS}`,

  // Settings
  SETTINGS_GET: `${EPM.SETTINGS}/${EPA.GET}`,
  SETTINGS_UPDATE: `${EPM.SETTINGS}/${EPA.UPDATE}`,
}
const EP = enumPermissions

export const constructedPermissionTree = {
  [EPM.AGENT_ITEMS]: {
    original: [EPA.LIST, EPA.GET, EPA.PROMOTION],
    modified: [EP.AGENT_ITEMS_LIST, EP.AGENT_ITEMS_GET, EP.AGENT_ITEMS_PROMOTION]
  },

  [EPM.ADMIN_USERS]: {
    original: [EPA.LIST, EPA.GET, EPA.CREATE, EPA.UPDATE, EPA.DELETE, EPA.VALIDATE],
    modified: [EP.ADMIN_USERS_LIST, EP.ADMIN_USERS_GET, EP.ADMIN_USERS_CREATE, EP.ADMIN_USERS_UPDATE, EP.ADMIN_USERS_DELETE,]
  },

  [EPM.ADMIN_COUNTRIES]: {
    original: [EPA.LIST, EPA.UPDATE, EPA.CREATE],
    modified: [EP.ADMIN_COUNTRIES_LIST, EP.ADMIN_COUNTRIES_UPDATE, EP.ADMIN_COUNTRIES_CREATE]
  },

  [EPM.ADMIN_CONTACT]: {
    original: [EPA.LIST],
    modified: [EP.ADMIN_CONTACT_LIST]
  },

  [EPM.ADMIN_RESERVATIONS]: {
    original: [EPA.LIST, EPA.UPDATE],
    modified: [EP.ADMIN_RESERVATIONS_LIST, EP.ADMIN_RESERVATIONS_UPDATE]
  },

  [EPM.ADMIN_CITIES]: {
    original: [EPA.LIST, EPA.UPDATE, EPA.CREATE, EPA.DELETE],
    modified: [EP.ADMIN_CITIES_LIST, EP.ADMIN_CITIES_UPDATE, EP.ADMIN_CITIES_CREATE, EP.ADMIN_CITIES_DELETE]
  },

  [EPM.ADMIN_TESTIMONIALS]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_TESTIMONIALS_LIST, EP.ADMIN_TESTIMONIALS_CREATE, EP.ADMIN_TESTIMONIALS_UPDATE, EP.ADMIN_TESTIMONIALS_DELETE]
  },

  [EPM.ADMIN_ICAL]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_ICAL_LIST, EP.ADMIN_ICAL_CREATE, EP.ADMIN_ICAL_UPDATE, EP.ADMIN_ICAL_DELETE]
  },

  [EPM.ADMIN_ITEMS]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE, EPA.GET, EPA.IMAGE_DELETE, EPA.PROMOTION, EPA.AGENT_DISABLE],
    modified: [EP.ADMIN_ITEMS_LIST, EP.ADMIN_ITEMS_CREATE, EP.ADMIN_ITEMS_UPDATE, EP.ADMIN_ITEMS_DELETE, EP.ADMIN_ITEMS_GET, EP.ADMIN_ITEMS_IMAGE_DELETE, EP.ADMIN_ITEMS_PROMOTION, EP.ADMIN_ITEMS_AGENT_DISABLE]
  },

  [EPM.ADMIN_AMENITIES]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_AMENITIES_LIST, EP.ADMIN_AMENITIES_CREATE, EP.ADMIN_AMENITIES_UPDATE, EP.ADMIN_AMENITIES_DELETE]
  },

  [EPM.ADMIN_AMENITIES_CATEGORIES]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_AMENITIES_CATEGORIES_LIST, EP.ADMIN_AMENITIES_CATEGORIES_CREATE, EP.ADMIN_AMENITIES_CATEGORIES_UPDATE, EP.ADMIN_AMENITIES_CATEGORIES_DELETE]
  },

  [EPM.ADMIN_PERMISSIONS]: {
    original: [EPA.GET, EPA.UPDATE],
    modified: [EP.ADMIN_PERMISSIONS_GET, EP.ADMIN_PERMISSIONS_UPDATE]
  },

  [EPM.PROFILE]: {
    original: [EPA.GET, EPA.UPDATE],
    modified: [EP.PROFILE_GET, EP.PROFILE_UPDATE]
  },

  [EPM.ADMIN_ANALYTICS]: {
    original: [EPA.ITEMS, EPA.PROMOTIONS],
    modified: [EP.ADMIN_ANALYTICS_ITEMS, EP.ADMIN_ANALYTICS_PROMOTIONS]
  },
  [EPM.SETTINGS]: {
    original: [EPA.GET, EPA.UPDATE],
    modified: [EP.SETTINGS_GET, EP.SETTINGS_UPDATE]
  },

}
const CPT = constructedPermissionTree

export const adminModules = [
  EPM.ADMIN_ANALYTICS,
  EPM.ADMIN_USERS,
  EPM.ADMIN_PERMISSIONS,
  EPM.ADMIN_TESTIMONIALS,
  EPM.ADMIN_AMENITIES,
  EPM.ADMIN_AMENITIES_CATEGORIES,
  EPM.ADMIN_COUNTRIES,
  EPM.ADMIN_CITIES,
  EPM.ADMIN_ITEMS,
  EPM.ADMIN_CONTACT,
  EPM.ADMIN_ICAL,
  EPM.ADMIN_RESERVATIONS,
]

export const agentModules = [
  EPM.AGENT_ITEMS
]


export const adminActions = [
  ...CPT[EPM.ADMIN_ANALYTICS].modified,
  ...CPT[EPM.ADMIN_USERS].modified,
  ...CPT[EPM.ADMIN_TESTIMONIALS].modified,
  ...CPT[EPM.ADMIN_AMENITIES].modified,
  ...CPT[EPM.ADMIN_AMENITIES_CATEGORIES].modified,
  ...CPT[EPM.ADMIN_PERMISSIONS].modified,
  ...CPT[EPM.ADMIN_COUNTRIES].modified,
  ...CPT[EPM.ADMIN_CITIES].modified,
  ...CPT[EPM.ADMIN_ITEMS].modified,
  ...CPT[EPM.ADMIN_CONTACT].modified,
  ...CPT[EPM.ADMIN_RESERVATIONS].modified,
  ...CPT[EPM.ADMIN_ICAL].modified
]

export const agentActions = [
  ...CPT[EPM.AGENT_ITEMS].modified,
]

export const commonModules = [EPM.PROFILE]
export const commonActions = [...CPT[EPM.PROFILE].modified]