
import React, { Suspense, useEffect, useState, lazy, useMemo } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import { enumRoles as role } from "data/enums/authorization";
import { Redirect404, RedirectLoggedInUsers, RequirePermission } from "utilities/routes";
import useApiSnackbar from "hooks/useApiSnackbar";

import SplashScreen from "components/loaders/splashScreen/index";
import RootErrorBoundary from "components/errorBoundary/rootErrorBoundary";

const Admission = React.lazy(() => import("./views/admission"));
const Admin = React.lazy(() => import("./views/admin"));

const Logout = React.lazy(() => import("./views/logout"))

/* const VersionChangeModal = lazy(() => import('components/modals/versionChangeModal')); */
// const AnimatedLayout = lazy(() => import('components/animationComponents/animatedLayout'));

const allAdminRoles = [role.ADMIN, role.AGENT]

const Fallback = ({ setSuspenseMounted }) => {
  useEffect(() => { setSuspenseMounted(true); return () => setSuspenseMounted(false) }, [setSuspenseMounted])
  return '';
};

const App = () => {

  const location = useLocation();
  const [suspenseMounted, setSuspenseMounted] = useState(true)

  const key = useMemo(() => {
    const pathname = location?.pathname || ''
    if (pathname.includes('admin')) return 1
    if (pathname.includes('admission')) return 3
    return pathname
  }, [location?.pathname])

  useApiSnackbar()

  return (
    <Box sx={{
      height: '100%', position: 'relative', backgroundColor: 'lightgray'
    }}>
      <RootErrorBoundary >

        <SplashScreen suspenseMounted={suspenseMounted} />

        <Suspense fallback={<Fallback setSuspenseMounted={setSuspenseMounted} />}>

          {/* <VersionChangeModal /> */}

          <Switch location={location} key={key}>
            <Route path="/admin/*" >
              <RequirePermission requiredRoles={allAdminRoles}><Admin /></RequirePermission>
            </Route>

            <Route path="/admission/*" >
              <RedirectLoggedInUsers><Admission /></RedirectLoggedInUsers>
            </Route>

            <Route path="/logout" ><Logout /></Route>

            <Route path="/" ><Redirect404 /></Route>

          </Switch>

        </Suspense>

      </RootErrorBoundary>
    </Box >
  );
}

export default App;