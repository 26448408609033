import { enumRoles, enumPermissions as permission } from "data/enums/authorization";
import { moduleNames } from "data/enums/modules";

const { ADMIN, AGENT } = enumRoles

const allRoles = [ADMIN, AGENT]
const allAdminRoles = [ADMIN, AGENT]


const routes = [
  {
    id: 'admin',
    auth: {
      roles: [ADMIN]
    },
    modules: [
      {
        id: moduleNames.ADMIN_ANALYTICS_DROPDOWN, to: '/admin/analytics',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_ANALYTICS_ITEMS] },
        routes: [
          {
            id: moduleNames.ADMIN_ANALYTICS_ITEMS, to: '/admin/analytics/items',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_ANALYTICS_ITEMS] },
          },
          {
            id: moduleNames.ADMIN_ANALYTICS_PROMOTIONS, to: '/admin/analytics/promotions',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_ANALYTICS_PROMOTIONS] },
          },
        ]
      },
      {
        id: moduleNames.ADMIN_USERS, to: '/admin/users',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_USERS_LIST] },
      },
      {
        id: moduleNames.ADMIN_VILLAS, to: '/admin/villas',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_ITEMS_LIST] },
      },
      {
        id: moduleNames.ADMIN_YACHTS, to: '/admin/yachts',
        auth: { roles: [ADMIN], permissions: [permission.ADMIN_ITEMS_LIST] },
      },
      {
        id: moduleNames.ADMIN_AMENITIES_DROPDOWN, to: '/admin/amenities',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_AMENITIES_LIST] },
        routes: [
          {
            id: moduleNames.ADMIN_AMENITIES, to: '/admin/amenities/single',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_AMENITIES_LIST] },
          },
          {
            id: moduleNames.ADMIN_AMENITIES_CATEGORIES, to: '/admin/amenities/categories',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_AMENITIES_CATEGORIES_LIST] },
          },
        ]
      },

      {
        id: moduleNames.ADMIN_TESTIMONIALS, to: '/admin/testimonials',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_TESTIMONIALS_LIST] },
      },
      {
        id: moduleNames.ADMIN_COUNTRIES, to: '/admin/countries',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_COUNTRIES_LIST] },
      },
      {
        id: moduleNames.ADMIN_CITIES, to: '/admin/cities',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_CITIES_LIST] },
      },
      {
        id: moduleNames.ADMIN_RESERVATIONS, to: '/admin/reservations',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_RESERVATIONS_LIST] },
      },
      {
        id: moduleNames.ADMIN_ICAL, to: '/admin/ical',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_ICAL_LIST] },
      },
      {
        id: moduleNames.ADMIN_CONTACT, to: '/admin/contact',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_CONTACT_LIST] },
      }
    ]
  },
  {
    id: 'agent',
    auth: {
      roles: allAdminRoles
    },
    modules: [

      {
        id: moduleNames.AGENT_ITEMS, to: '/admin/agent-items',
        auth: { roles: allAdminRoles, permissions: [permission.AGENT_ITEMS_LIST] },
      },
    ]
  },
  {
    id: 'system',
    auth: {
      roles: allRoles
    },
    modules: [
      {
        id: moduleNames.ADMIN_PROFILE, to: '/admin/profile',
        auth: { roles: allAdminRoles, presmissions: [] },
      }
    ]
  },

]

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_STAGING !== 'true') {
  const devRoutes = {
    id: 'dev',
    auth: {
      roles: allRoles
    },
    modules: [
      {
        id: `${moduleNames.DEV_BUILDER_DROPDOWN}_admin`, to: '/admin/builder',
        auth: { roles: allAdminRoles, permissions: [] },
        routes: [
          {
            id: `${moduleNames.DEV_BUILDER_MODULE}_admin`, to: '/admin/builder/module',
            auth: { roles: allAdminRoles, permissions: [] },
          }
        ]
      }
    ]
  }
  routes.push(devRoutes)
}


export default routes