export const moduleNames = {

  // ADMIN MODULES
  ADMIN_HOME: 'adminHome',
  ADMIN_USERS: 'adminUsers',
  ADMIN_TESTIMONIALS: 'adminTestimonials',
  ADMIN_PROFILE: 'adminProfile',
  ADMIN_AMENITIES_DROPDOWN: 'adminAmenitiesDropdown',
  ADMIN_AMENITIES: 'adminAmenities',
  ADMIN_AMENITIES_CATEGORIES: 'adminAmenitiesCategories',
  ADMIN_COUNTRIES: 'adminCountries',
  ADMIN_CITIES: 'adminCities',
  ADMIN_VILLAS: 'adminVillas',
  ADMIN_YACHTS: 'adminYachts',
  ADMIN_CONTACT: 'adminContact',
  ADMIN_RESERVATIONS: 'adminReservations',
  ADMIN_ANALYTICS_DROPDOWN: 'adminAnalyticsDropdown',
  ADMIN_ANALYTICS_ITEMS: 'adminAnalyticsItems',
  ADMIN_ANALYTICS_PROMOTIONS: 'adminAnalyticsPromotions',
  ADMIN_ANALYTICS_PROMOTIONS_DETAILS: 'adminAnalyticsPromotions',
  ADMIN_ICAL: 'adminIcal',

  //AGENT MODULES
  AGENT_ITEMS: 'agentItems',
  PROFILE: 'profile',

  //SYSTEM MODULES
  LOGOUT: 'logout',

  //DEV MODULES
  DEV_BUILDER_DROPDOWN: 'devBuilderDropdown',
  DEV_BUILDER_MODULE: 'devModuleBuilder',
}