import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore, persistReducer, createMigrate,
  FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux';

import authReducer, { authMigration } from './slices/authSlice'
import appReducer, { appMigration } from './slices/appSlice'
import tableReducer, { tableMigration } from './slices/tableSlice'
import preselectReducer, { preselectMigration } from './slices/preselectSlice'
import snackbarReducer from './slices/snackbarSlice'
import snackbarBottomReducer from './slices/snackbarBottomSlice'
import notificationReducer from './slices/notificationSlice'


const MIGRATION_DEBUG = process.env.NODE_ENV === 'development'

const persistConfig = {
  key: 'root', version: 1, storage,
  blacklist: ['auth']
}

const authPersistConfig = {
  key: 'auth', version: 1, storage,
  migrate: createMigrate(authMigration, { debug: MIGRATION_DEBUG })
}

const appPersistConfig = {
  key: 'app', version: 1, storage,
  migrate: createMigrate(appMigration, { debug: MIGRATION_DEBUG })
}

const tablePersistConfig = {
  key: 'table', version: 1, storage,
  migrate: createMigrate(tableMigration, { debug: MIGRATION_DEBUG })
}

const preselectPersistConfig = {
  key: 'preselect', version: 1, storage,
  migrate: createMigrate(preselectMigration, { debug: MIGRATION_DEBUG })
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  app: persistReducer(appPersistConfig, appReducer),
  table: persistReducer(tablePersistConfig, tableReducer),
  preselect: persistReducer(preselectPersistConfig, preselectReducer),
  snackbar: snackbarReducer,
  snackbarBottom: snackbarBottomReducer,
  notification: notificationReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
});
const persistor = persistStore(store)

export { store, persistor };
