import { colorsObject } from "data/colors/colorPalettes"
import { colorPallet as c } from "data/colors/colorPalettes"

export const apiVersions = {
  V1: 'v1',
  V2: 'v2'
}

export const apiPaths = {
  ADMIN_USERS: 'admin.users',
  ADMIN_PERMISSIONS: 'admin.permissions',
  ADMIN_PROFILE: 'admin.profile',
  ADMIN_AMENITIES: 'admin.amenities',
  ADMIN_AMENITIES_CATEGORIES: 'admin.amenities-categories',
  ADMIN_COUNTRIES: 'admin.countries',
  ADMIN_TESTIMONIALS: 'admin.testimonials',
  ADMIN_ITEMS: 'admin.items',
  ADMIN_CITIES: 'admin.cities',
  ADMIN_CONTACT: 'admin.contact',
  ADMIN_RESERVATIONS: 'admin.reservations',
  ADMIN_ICAL: 'admin.items-ical',
  ADMIN_ANALYTICS: 'admin.analytics',
  AGENT_ITEMS: 'agent.items',
  SETTINGS: 'settings',
  PROFILE: 'admin.profile'
}

export const apiActions = {
  LIST: 'list',
  GET: 'get',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  PROMOTION: 'promotion',
  IMAGE_DELETE: 'image.delete',
  ITEMS: 'items',
  PROMOTIONS: 'promotions',
  AGENT_DISABLE: 'agent-disable',
  PROMOTIONS_DETAILS: 'promotions.details'
}

export const boolean = {
  boolean: {
    label: 'Boolean true/false',
    data: {
      default: {
        label: 'No',
        value: 'false',
        theme: colorsObject().englishRed,
        booleanValue: 0,
      },
      true: {
        label: 'Yes',
        value: 'true',
        theme: colorsObject().forrestGreenCrayola,
        booleanValue: 1,
      },
      false: {
        label: 'No',
        value: 'false',
        theme: colorsObject().englishRed,
        booleanValue: 2,
      },
    }
  },
  booleanString: {
    label: 'Boolean "true"/"false"',
    data: {
      default: {
        label: 'No',
        value: 'false',
        theme: colorsObject().englishRed,
        booleanValue: 0,
      },
      true: {
        label: 'Yes',
        value: 'true',
        theme: colorsObject().forrestGreenCrayola,
        booleanValue: 1,
      },
      false: {
        label: 'No',
        value: 'false',
        theme: colorsObject().englishRed,
        booleanValue: 2,
      },
    }
  },
  booleanYesNo: {
    label: 'Boolean "Yes"/"No"',
    data: {
      default: {
        label: 'No',
        value: 'no',
        theme: colorsObject().englishRed,
        booleanValue: 0,
      },
      yes: {
        label: 'Yes',
        value: 'yes',
        theme: colorsObject().forrestGreenCrayola,
        booleanValue: 1,
      },
      no: {
        label: 'No',
        value: 'no',
        theme: colorsObject().englishRed,
        booleanValue: 2,
      },
    }
  },
  booleanNumber: {
    label: 'Boolean 1/0',
    data: {
      default: {
        label: 'No',
        value: 0,
        theme: colorsObject().englishRed,
        booleanValue: 0,
      },
      '1': {
        label: 'Yes',
        value: 1,
        theme: colorsObject().forrestGreenCrayola,
        booleanValue: 1,
      },
      '0': {
        label: 'No',
        value: 0,
        theme: colorsObject().englishRed,
        booleanValue: 2,
      },
    }
  }
}

export const common = {
  ...boolean,
  devices: {
    label: 'Devices',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'desktop': {
        label: 'Desktop',
        value: 'desktop',
        theme: colorsObject().starCommandBlue,
      },
      'mobile': {
        label: 'Mobile',
        value: 'mobile',
        theme: colorsObject().rebeccaPurple,
      },
      'tablet': {
        label: 'Tablet',
        value: 'tablet',
        theme: colorsObject().ochre,
      },
      'unknown': {
        label: 'Unknown',
        value: 'unknown',
        theme: colorsObject().darkByzantium,
      }
    },
  },
  empty: {
    label: 'Empty',
    data: {},
  }
}

export const user = {
  usersStatus: {
    label: 'Users status',
    data: {
      default: {
        label: "Unknown",
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: "Admin",
        value: 1,
        theme: colorsObject().amazon,
      },
      2: {
        label: "Account Manager",
        value: 2,
        theme: colorsObject().burntSienna,
      },
      3: {
        label: "Advertiser",
        value: 3,
        theme: colorsObject().rebeccaPurple,
      },
      4: {
        label: "Affiliate",
        value: 4,
        theme: colorsObject().starCommandBlue,
      },
      5: {
        label: "Support",
        value: 5,
        theme: colorsObject().amaranth,
      },
      6: {
        label: "Finance",
        value: 6,
        theme: colorsObject().flickrPink,
      },
      99: {
        label: "Banned",
        value: 99,
        theme: colorsObject().englishRed,
      },
    }
  },
  usersType: {
    label: 'Users type',
    data: {
      default: {
        label: "Unknown",
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: "Internal",
        value: 1,
        theme: colorsObject().starCommandBlue,
      },
      2: {
        label: "External",
        value: 2,
        theme: colorsObject().royalPurple,
      },
    }
  },
}

export const items = {
  itemsPaymentType: {
    label: 'Items payment type',
    data: {
      default: {
        label: "Unknown",
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'revolut': {
        label: "Revolut",
        value: 'revolut',
        theme: colorsObject().amazon,
      },
      'on-request': {
        label: "On request",
        value: 'on-request',
        theme: colorsObject().burntSienna,
      },

    }
  }
}


