import { convertToLowerCase } from "utilities/dataFormatting/text"

const apiProtocol = 'https'
const apiOrigin = 'api.uniquestays.eu' /* SHOULD_CHANGE */

const getFlagUrl = (code = '', size = 24) =>
  `${config.storageUrl}/country/flags/${size}x${size}/${code ? convertToLowerCase(code) : ''}.png`


const config = {
  project: 'Uniquestays',
  apiUrl: `${apiProtocol}://${apiOrigin}/`,
  storageUrl: 'https://storage.uniquestays.eu',
  resourcesUrl: {
    countryFlag: {
      '16': (code) => getFlagUrl(code, 16),
      '24': (code) => getFlagUrl(code),
      '32': (code) => getFlagUrl(code, 32),
      '48': (code) => getFlagUrl(code, 48),
      '64': (code) => getFlagUrl(code, 64),
      '128': (code) => getFlagUrl(code, 128)
    }
  },
}
export { config }
export default config